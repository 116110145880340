
import { Quote, Text, Title, Table } from 'components/semantic';
import { PageHeading } from 'components/elements';

export function ManualSpecialPage() {
  return (
    <>
      <PageHeading>
        Speciales
      </PageHeading>

      <Title>Parade</Title>

      <Text>
        {`Il est possible de parer une attaque dont le personnage a pleinement conscience : cela se fait avec un test de ${CHARACTER.SKILLS.MASTERY.AGILITY}.`}
      </Text>

      <Text>
        {`Il existe plusieurs types de parades :`}
      </Text>

      <Text>
        {`⇒ Parer avec un autre membre de son corps : faire ainsi permet de rediriger les dégâts vers ce membre au lieu de celui visé par l'attaquant.`}
      </Text>

      <Text>
        {`⇒ Parer pour quelqu'un : intervient pour une personne proche afin de prendre les dégâts à sa place.`}
      </Text>

      <Text>
        {`⇒ Parer avec un objet : interpose un objet devant l'attaque. Il faut avoir l'objet en main et ce dernier doit être assez grand pour bloquer l'attaque. Tant que l'objet n'est pas brisé, faire ainsi permet de mitiger entièrement l'attaque.
Toutefois, les dégâts sont appliqués sur l'objet à la place.`}
      </Text>

      <Title>Esquive</Title>

      <Text>
        {`Effectuer une esquive est une action particulière : il est possible d'esquiver les attaques de mêlée ou les projectiles lents, mais pas les balles. Tout comme pour la parade, il faut avoir pleinement conscience de l'attaque.`}
      </Text>

      <Text>
        {`Ce contre se fait sur la compétence ${CHARACTER.SKILLS.MASTERY.AGILITY}.`}
      </Text>

      <Quote>
        {`Il n'est pas possible d'esquiver les réussites critiques`}
      </Quote>

      <Text>
        {`Une esquive particulière consiste à se jeter au sol, de la sorte un bonus de 25 est à ajouter sur le test, cependant le personnage se retrouvera au sol ensuite.`}
      </Text>

      <Title>Delayer son action</Title>

      <Text>
        {`Un personnage dont c'est le tour de résolution de son action peut décider de l'annuler.`}
      </Text>

      <Text>
        {`S'il fait ainsi, il pourra alors agir à la fin de la résolution de l'action des participants au combat, mais ne pourra toutefois plus faire qu'une action rapide .`}
      </Text>

      <Title>Attaque a outrance</Title>

      <Text>
        {`L'attaque à outrance consiste à utiliser les armes dans chaque main en même temps afin d'effectuer deux attaques dans le même tour.`}
      </Text>

      <Text>
        {`Utiliser sa main inhabituelle apporte un malus de 25 % sur les actions qui l'utilisent et l'utilisation des mains en simultanée ajoute un autre malus de 25 % sur les actions de ces deux dernières.`}
      </Text>

      <Title>La provocation</Title>

      <Text>
        {`Pour provoquer un ennemi lors d'un combat, plusieurs facteurs sont à prendre en compte comme la méthode utilisée et l'intelligence de la cible à provoquer.`}
      </Text>

      <Table data={[[``, `Énerver`, `Raisonner`], [`Humanoïde`, `Rhétorique`, `Rhétorique`], [`Bestial`, `Intimidation`, `Empathie`]]}></Table>

      <Title>Le repos</Title>

      <Text>
        {`Il est possible de dédier une action pleine au repos du personnage.`}
      </Text>

      <Text>
        {`Faire ainsi va réduire son score de Fatigue de 1.`}
      </Text>
    </>
  );
}
