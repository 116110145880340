import { Spells } from 'data/metadata';

const data = Object.freeze([
  {
    cost: 2,
    description: `Protège l'esprit d'une personne contre les intrusions psychiques quelconques.`,
    name: `Barrière psychique`,
    requirements: [`contact`],
    types: [
      Spells.Types.CAST,
      Spells.Types.CAST_CHANNEL,
    ],
  },
  {
    cost: 2,
    description: `Permet de comprendre une personne, quelque soit la langue, quelque soit la race.`,
    name: `Compréhension`,
    requirements: [`contact`],
    types: [
      Spells.Types.CAST,
      Spells.Types.CAST_CHANNEL,
    ],
  },
  {
    additionalEffects: [
      `Effet : Mental -1`,
      `Surcharge : Mental -1 supplémentaire`,
    ],
    cost: 2,
    description: `Affaibli le moral d'une personne.`,
    name: `Épuisement`,
    requirements: [`contact`],
    types: [
      Spells.Types.CAST,
      Spells.Types.CHANNEL,
    ],
  },
  {
    additionalEffects: [
      `Effet : ${CHARACTER.SKILLS.DEFENSES.WILL} +10 ou -10`,
      `Effet supplémentaire si la personne ignore le sort : ${CHARACTER.SKILLS.DEFENSES.WILL} +10 ou -10`,
      `Surcharge : double les bonus ou malus`,
    ],
    cost: 2,
    description: `Augmente ou diminue le niveau d'assurance d'une personne.`,
    name: `Ressenti : assurance`,
    requirements: [`contact`],
    types: [
      Spells.Types.CAST,
      Spells.Types.CAST_CHANNEL,
    ],
  },
  {
    additionalEffects: [
      `Effet : ${CHARACTER.SKILLS.DEFENSES.FOCUS} +10 ou -10`,
      `Effet supplémentaire si la personne ignore le sort : ${CHARACTER.SKILLS.DEFENSES.FOCUS} +10 ou -10`,
      `Surcharge : double les bonus ou malus`,
    ],
    cost: 2,
    description: `Augmente ou diminue le niveau d'attention d'une personne.`,
    name: `Ressenti : attention`,
    requirements: [`contact`],
    types: [
      Spells.Types.CAST,
      Spells.Types.CAST_CHANNEL,
    ],
  },
  {
    additionalEffects: [
      `Effet : ${CHARACTER.SKILLS.DEFENSES.COLD_BLOOD} +10 ou -10`,
      `Effet supplémentaire si la personne ignore le sort : ${CHARACTER.SKILLS.DEFENSES.COLD_BLOOD} +10 ou -10`,
      `Surcharge : double les bonus ou malus`,
    ],
    cost: 2,
    description: `Augmente ou diminue le niveau de confiance d'une personne.`,
    name: `Ressenti : confiance`,
    requirements: [`contact`],
    types: [
      Spells.Types.CAST,
      Spells.Types.CAST_CHANNEL,
    ],
  },
  {
    cost: 2,
    description: `Communique un message à une personne directement dans son esprit.`,
    name: `Télépathie`,
    requirements: [`contact`],
    types: [
      Spells.Types.SUMMON,
      Spells.Types.CAST_CHANNEL,
    ],
  },
  {
    additionalEffects: [
      `Effet : ${CHARACTER.SKILLS.DEFENSES.WILL} +20 ou -20`,
      `Surcharge : ${CHARACTER.SKILLS.DEFENSES.WILL} +20 ou -20 supplémentaires`,
    ],
    area: `4 m²`,
    cost: 4,
    description: `Une aura psychique émane du lanceur ce qui rassure ou panique les personnes proches.`,
    name: `Aura : assurance`,
    types: [
      Spells.Types.CAST,
      Spells.Types.CAST_CHANNEL,
    ],
  },
  {
    additionalEffects: [
      `Effet : ${CHARACTER.SKILLS.DEFENSES.FOCUS} +20 ou -20`,
      `Surcharge : ${CHARACTER.SKILLS.DEFENSES.FOCUS} +20 ou -20 supplémentaires`,
    ],
    area: `4 m²`,
    cost: 4,
    description: `Une aura psychique émane du lanceur ce qui aiguise ou émousse l'attention des personnes proches.`,
    name: `Aura : attention`,
    types: [
      Spells.Types.CAST,
      Spells.Types.CAST_CHANNEL,
    ],
  },
  {
    additionalEffects: [
      `Effet : ${CHARACTER.SKILLS.DEFENSES.COLD_BLOOD} +20 ou -20`,
      `Surcharge : ${CHARACTER.SKILLS.DEFENSES.COLD_BLOOD} +20 ou -20 supplémentaires`,
    ],
    area: `4 m²`,
    cost: 4,
    description: `Une aura psychique émane du lanceur ce qui augmente ou réduit la confiance des personnes proches.`,
    name: `Aura : confiance`,
    types: [
      Spells.Types.CAST,
      Spells.Types.CAST_CHANNEL,
    ],
  },
  {
    additionalEffects: [
      `Surcharge : permet d'effleurer les souvenirs également`,
      `Attention : faire ainsi expose l'esprit et présente un risque pour la santé mentale, ce qui peut augmente la Psychose ou donner des folies`,
    ],
    cost: 4,
    description: `Effleure l'esprit d'une personne afin de voir à quoi elle pense sur l'instant.`,
    name: `Effleurer les pensées`,
    requirements: [`contact`],
    types: [Spells.Types.CAST],
  },
  {
    additionalEffects: [
      `Effet : inflige l'état de santé « paralysé » pour 1 tour`,
      `Surcharge : inflige l'état de santé « paralysé » pour 2 tours`,
    ],
    cost: 4,
    description: `Assomme une personne et la rend confuse ce qui lui fait perdre ses moyens.`,
    name: `Hébétude`,
    requirements: [`contact`],
    types: [Spells.Types.CAST],
  },
  {
    additionalEffects: [
      `Effet : ignore une folie pour 24 heures`,
      `Attention : faire ainsi expose l'esprit et présente un risque pour la santé mentale, ce qui peut augmente la Psychose ou donner des folies`
    ],
    cost: 4,
    description: `Apaise les traumatismes d'une personne ce qui lui permet d'ignorer temporairement ses folies.`,
    name: `Paix intérieure`,
    requirements: [`contact`],
    types: [Spells.Types.CAST],
  },
  {
    additionalEffects: [
      `Effet : lancer ce sort autant de fois que le Mental de la personne va la faire tomber inconsciente`,
      `Surcharge : augmente les dégâts à 45 + 8x1d10`,
    ],
    cost: 6,
    damage: `30 + 6x1d10 à la tête, Ignore l'armure`,
    description: `Attaque l'esprit d'une personne afin d'en défaire ses rouages.`,
    name: `Attaque mentale`,
    requirements: [`contact`],
    types: [Spells.Types.CAST],
  },
  {
    additionalEffects: [
      `Effet : soigne une folie à la personne`,
      `Attention : faire ainsi expose l'esprit et présente un risque pour la santé mentale, ce qui peut augmente la Psychose ou donner des folies`
    ],
    cost: 6,
    description: `Modifie les filaments qui composent l'esprit d'une personne afin de lui soigner une folie.`,
    name: `Chirurgie psychiatrique`,
    requirements: [`contact`],
    types: [Spells.Types.CAST],
  },
  {
    additionalEffects: [
      `Surcharge : il devient possible de lui faire faire n'importe quoi`,
      `Attention : faire ainsi expose l'esprit et présente un risque pour la santé mentale, ce qui peut augmente la Psychose ou donner des folies`,
    ],
    cost: 6,
    description: `Contrôle une personne pour son prochain tour. Il n'est pas possible de le faire agir contre ses principes.`,
    name: `Contrôle mental`,
    requirements: [`contact`],
    types: [
      Spells.Types.CAST,
      Spells.Types.CAST_CHANNEL,
    ],
  },
  {
    additionalEffects: [
      `Surcharge : permet d'intégrer de nouveaux souvenirs dans l'esprit de la personne`,
      `Attention : faire ainsi expose l'esprit et présente un risque pour la santé mentale, ce qui peut augmente la Psychose ou donner des folies`,
    ],
    cost: 6,
    description: `Intègre dans l'esprit d'une personne une illusion auditive, visuelle, ou olfactive.`,
    name: `Leurrer l'esprit`,
    requirements: [`contact`],
    types: [
      Spells.Types.SUMMON,
      Spells.Types.CAST_CHANNEL,
    ],
  },
  {
    cost: 6,
    description: `Crée un lien psychique entre deux personnes : les blessures infligées à l'une d'elles sont répercutées sur l'autre.`,
    name: `Lien psychique`,
    requirements: [`contact`],
    types: [Spells.Types.CHANNEL],
  },
  {
    additionalEffects: [
      `Effet : -1 Endurance permanent sur la personne`,
      `Effet : -10 Volonté permanent sur la personne`,
      `Attention : faire ainsi expose l'esprit et présente un risque pour la santé mentale, ce qui peut augmente la Psychose ou donner des folies`
    ],
    cost: 6,
    description: `Force le sevrage d'une personne à une drogue.`,
    name: `Sevrage`,
    requirements: [`contact`],
    types: [Spells.Types.CAST],
  },
]);

export default data.map((i) => ({
  ...i,
  schools: [{ name: Spells.School.MIND }],
}));
