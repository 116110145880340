export default Object.freeze({
  HUMAN: [
    `Points de Chance x2`,
    `Chaque niveau augmente une compétence de 5 (jamais la même)`,
    `Chaque niveau pair augmente une magie de 5 (jamais la même)`,
  ],
  SALBEK: [
    `Maîtrise -20`,
    `Temps de sommeil ÷2`,
    `Seuil d'effet des radiations x2`,
    `Insensible aux poisons`,
  ],
  THOG: [
    `Savoirs -20`,
    `Charge +3`,
    `Endurance +3`,
    `Insensible aux maladies`,
  ],
  CYBORG: [
    `Social -20`,
    `Bonus des compétences spécialisées x2`,
    `Bonus des magies spécialisées x2`,
    `Deux membres sont cybernétiques (insensibles aux effets biologiques)`,
  ],
  ELEMENTAL: [
    `Combat -20`,
    `Points de magie par niveau +4`,
    `Magie +5`,
    `Pour les magies spécialisées : coût des sorts -2`,
    `Deux membres sont magiques : dégâts reçus ÷2`,
  ],
  ADRULEN: [
    `Résistances -20`,
    `Mental +5`,
    `Seuil de Psychose x2`,
    `Points divins +10`,
  ],
});
