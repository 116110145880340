const aptitudes = [
  {
    effect: [`Chances de succès critique lors d'attaques contre des humanoïdes +10%`],
    name: `Anthopologue`,
  },
  {
    effect: [`Réduit le prix des objets achetés d'environ 30%`],
    name: `Commercial`,
  },
  {
    effect: [`Résistance aux effets de froid et de chaud augmentée de 3 degrés`],
    name: `Dur à cuire`,
  },
  {
    effect: [`Les gains de radiations soignent les dégâts`],
    name: `Éponge à radiations`,
  },
  {
    effect: [`Magie +3`],
    name: `Esprit entraîné`,
  },
  {
    effect: [`Double la durée des substances chimiques injectées`],
    name: `Expert en chimie`,
  },
  {
    effect: [
      `${CHARACTER.SKILLS.WEAPONS.THROWING} +5`,
      `Feu et Terre +5`,
    ],
    name: `Expert en démolition`,
  },
  {
    effect: [
      `${CHARACTER.SKILLS.WEAPONS.LIGHT} +5`,
      `Vent et Foudre +5`
    ],
    name: `Fana d'armes`,
  },
  {
    effect: [`Chances de succès critique lors d'attaques contre des mutants +10%`],
    name: `Généticien`,
  },
  {
    effect: [
      `Supprime une folie < 51`,
      `Supprime les points de Psychose`,
    ],
    name: `Internement`,
  },
  {
    effect: [
      `${CHARACTER.SKILLS.WEAPONS.HEAVY} +5`,
      `Lumière et Vie +5`
    ],
    name: `La taille compte`,
  },
  {
    effect: [
      `Seuil de Psychose +3`,
      `Ombre et Mort +5`,
    ],
    name: `Nécrophile`,
  },
  {
    effect: [
      `Il n'est plus nécessaire de faire de tests de Volonté pour dépasser sa Fatigue`,
      `Moral +3`,
    ],
    name: `Optimisme infaillible`,
  },
  {
    effect: [
      `${CHARACTER.SKILLS.WEAPONS.MELEE} +5`,
      `Eau et Nature +5`,
    ],
    name: `Poings de fer`,
  },
  {
    effect: [
      `Double la Magie récupérée par l'action de Méditation`,
      `Le premier tour de maintien de sort ne coûte pas de Magie`,
    ],
    name: `Professeur de Yoga`,
  },
  {
    effect: [`Charge +5`],
    name: `Reins solides`,
  },
  {
    effect: [`Chances de succès critique lors d'attaques contre des robots +10%`],
    name: `Roboticien`,
  },
  {
    effect: [`Points de chance +3`],
    name: `Veinard`,
    usable: 2,
  },
  {
    effect: [`Chances de succès critique lors d'attaques contre des bêtes +10%`],
    name: `Zoologue`,
  },
];

export default aptitudes.map((a) => ({ ...a, level: 2 }));
