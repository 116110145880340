import { Element, Type, Subtype } from 'data/metadata';

const data = [
  {
    damage: `12 + 2x1d8`,
    description: `Équivalent Boomrang, sauf que celui-ci revient`,
    name: `Bolas`,
    range: `Courte`,
    value: 20,
    weight: 2,
  },
  {
    damage: `16 + 8x1d4 + DR`,
    description: `Équivalent Shuriken`,
    name: `Couteau de lancer`,
    range: `Moyenne`,
    value: 10,
    weight: 0.2,
  },
  {
    damage: `40 + 8x1d8 + 2xDR`,
    name: `Disques à énergie`,
    range: `Moyenne`,
    value: 95,
    weight: 1,
  },
  {
    damage: `10 + 1d10`,
    effects: `Dégâts x 5 + Onde EM 10 sec. sur les robots`,
    name: `Grenade à impulsions`,
    value: 15,
    weight: 0.5,
    zone: `4`,
  },
  {
    effects: `Détruit les vents magiques dans la zone pour 2 tours`,
    name: `Grenade anti-magique`,
    value: 110,
    weight: 0.5,
    zone: `3`,
  },
  {
    damage: `80 + 4x1d20`,
    name: `Grenade d'avant-guerre`,
    value: 140,
    weight: 0.5,
    zone: `3`,
  },
  {
    effects: `Réceptacle pour un poison ou un gaz qui sera diffusé à l'explosion
    Idéal pour un fumigène`,
    name: `Grenade de diffusion`,
    value: 55,
    weight: 0.5,
    zone: `5`,
  },
  {
    effects: `Aveugle 9 secondes`,
    name: `Grenade flash`,
    value: 20,
    weight: 0.5,
    zone: `10`,
  },
  {
    damage: `40 + 2x1d20`,
    description: `La version "Fa6" explose 1 seconde après être dégoupillée`,
    name: `Grenade Frag`,
    value: 15,
    weight: 0.5,
    zone: `2`,
  },
  {
    damage: `20 + 2x1d20`,
    effects: `Applique Brûlure III ou moins`,
    name: `Grenade incendiaire`,
    value: 25,
    weight: 0.5,
    zone: `2`,
  },
  {
    effects: `Ralentit le temps dans la zone pendant 9 secondes`,
    name: `Grenade temporelle`,
    value: 2250,
    weight: 0.5,
    zone: `2`,
  },
  {
    damage: `30 + 5x1d6 + 2xDR`,
    name: `Javeline`,
    range: `Longue`,
    value: 15,
    weight: 1,
  },
  {
    damage: `10 + 10x1d4`,
    effects: `Avec des clous
    Capable d'être rattaché à un robot araignée pour en gérer le déplacement à distance`,
    name: `Mine artisanale`,
    value: 25,
    weight: 2,
    zone: `4`,
  },
  {
    damage: `40 + 2x1d20`,
    name: `Mine Frag`,
    value: 15,
    weight: 2,
    zone: `2`,
  },
  {
    damage: `80 + 4x1d20`,
    deescription: `Équivalent C4`,
    name: `Mine d'avant-guerre`,
    value: 125,
    weight: 2,
    zone: `3`,
  },
  {
    damage: `20 + 2x1d20`,
    effects: `Applique Brûlure III ou moins`,
    name: `Mine incendiaire`,
    value: 30,
    weight: 2,
    zone: `2`,
  },
  {
    effects: `Réceptacle pour un poison ou un gaz qui sera diffusé à l'explosion
    Idéal pour un fumigène`,
    name: `Mine de diffusion`,
    value: 40,
    weight: 2,
    zone: `5`,
  },
  {
    effects: `Détruit les vents magiques dans la zone pour 2 tours`,
    name: `Mine anti-magique`,
    value: 105,
    weight: 2,
    zone: `3`,
  },
  {
    damage: `10 + 1d10`,
    effects: `Dégâts x 5 + Onde EM 10 sec. sur les robots`,
    name: `Mine à impulsions`,
    value: 20,
    weight: 2,
    zone: `4`,
  },
  {
    effects: `Ralentit le temps dans la zone pendant 9 secondes`,
    name: `Mine temporelle`,
    value: 2480,
    weight: 10,
    zone: `2`,
  },
  {
    damage: `40 + 12x1d4`,
    effects: `Nécessite des outils pour l'amorçage`,
    name: `Piège à loups`,
    value: 20,
    weight: 5,
  },
  {
    effects: `Provoque une flaque d'eau
    Des racines jaillissent dans la zone et saisissent les personnes à l'intérieur pendant 10 secondes`,
    name: `Pierre runique Zam-Fel-Koh`,
    value: 125,
    weight: 2,
    zone: `0,5`,
  },
  {
    damage: `20 + 1d20`,
    effects: `Applique Brûlure I
    La zone émane de la lumière pendant 10 secondes`,
    name: `Pierre runique Doh-Nak-Meh`,
    value: 125,
    weight: 2,
    zone: `0,5`,
  },
  {
    effects: `Projette les personnes dans la zone dans les airs à ~2 mètres
    Provoque un trou de ~1 mètre dans la zone`,
    name: `Pierre runique Kil-Rah-Moj`,
    value: 125,
    weight: 2,
    zone: `0,5`,
  },
  {
    effects: `Gèle la zone touchée pendant 10 secondes
    Épuise temporairement les personnes à l'intérieur pendant 10 secondes`,
    name: `Projectile runique Jui-Vok-Fli`,
    range: `Courte`,
    value: 150,
    weight: 0.5,
    zone: `0,5`,
  },
  {
    effects: `Déclenche une bourrasque qui repousse la personne de ~2 mètres
    Applique Brûlure III ou moins`,
    name: `Projectile runique Dym-Wik-Daf`,
    range: `Courte`,
    value: 150,
    weight: 0.5,
    zone: `2`,
  },
  {
    effects: `Une vase boueuse épaisse ralentit la personne pendant 10 secondes
    La lumière ne passe plus au travers de la zone pendant 10 secondes`,
    name: `Projectile runique Qoh-Kuh-Cei`,
    range: `Courte`,
    value: 150,
    weight: 0.5,
    zone: `0,5`,
  },
];

export default data.map((i) => ({
  ...i,
  element: Element.ITEM,
  subtype: Subtype.Weapon.EXPLOSIVES,
  type: Type.Item.WEAPON,
}));
