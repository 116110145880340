import { Monsters } from 'data/metadata';

const data = [
  {
    abilities: [Monsters.Skills.Envol(50)],
    armor: 1,
    attacks: [`80 % : applique Radiations III`],
    damage: [`4 + DR + 1d4`, ``, ``],
    hit: [70, 0, 0],
    name: `Mut-cafard`,
    speed: 2,
  },
  {
    attacks: [`50 % : applique Poison M- pour 12 tours`],
    damage: [`6 + DR + 1d4`, ``, ``],
    hit: [60, 0, 0],
    initiative: 2,
    name: `Mut-mante`,
    speed: 2,
  },
  {
    attacks: [`25 % : applique Saignements I pour 6 tours`],
    damage: [`5 + DR  `, ``, ``],
    hit: [75, 0, 0],
    initiative: 4,
    name: `Mut-moustique`,
    passives: [Monsters.Skills.Virevoltant(35)],
    speed: 3,
  },
  {
    abilities: [Monsters.Skills.Enfouissement],
    armor: 3,
    attacks: [
      `25 % : applique Radiations I`,
      `25 % : applique Poison D pour 6 tours`,
    ],
    damage: [`6 + 1d6`, ``, ``],
    hit: [65, 0, 0],
    name: `Mut-crabe`,
    passives: [`Pas d'armure à la tête`],
    speed: 2,
  },
  {
    abilities: [Monsters.Skills.Charge(2, 4)],
    damage: [`6 + 1d6`, ``, ``],
    hit: [45, 0, 0],
    name: `Mut-vache`,
    speed: 2,
  },
  {
    abilities: [Monsters.Skills.Charge(2, 4)],
    armor: 2,
    damage: [`6 + 1d8`, ``, ``],
    hit: [65, 0, 0],
    initiative: 1,
    name: `Mut-sanglier`,
    passives: [
      `Armure à la tête : 3`,
      `Armure dans le dos : 6`
    ],
    speed: 3,
  },
  {
    abilities: [Monsters.Skills.Tunnel],
    armor: 1,
    damage: [`6 + DR + 1d6`, ``, ``],
    hit: [75, 0, 0],
    initiative: 4,
    name: `Mut-taupe`,
    speed: 4,
  },
  {
    attacks: [
      `40 % : applique Saignements I permanent`,
      `20 % : transmets la Rage`,
    ],
    damage: [`6 + 1d6`, ``, ``],
    hit: [80, 0, 0],
    initiative: 4,
    name: `Chien sauvage`,
    passives: [Monsters.Skills.Group(`2 à 4 [1 + 1d3]`)],
    speed: 5,
  },
  {
    armor: 1,
    attacks: [`80 % : applique Poison M- pour 12 tours`],
    damage: [`8 + 1d6`, ``, ``],
    hit: [80, 0, 0],
    name: `Mut-fourmi géante`,
    speed: 2,
  },
  {
    armor: 12,
    attacks: [`80 % : applique Poison D pour 120 tours`],
    damage: [`8 + 1d6`, ``, ``],
    hit: [80, 0, 0],
    initiative: 2,
    name: `Mut-scorpion`,
    passives: [`Pas d'armure à la tête`],
    speed: 3,
  },
  {
    attacks: [`50 % : applique Poison D pour 6 tours`],
    damage: [`4 + 1d6`, ``, ``],
    hit: [80, 0, 0],
    initiative: 6,
    name: `Mut-moustique virulent`,
    passives: [
      Monsters.Skills.Virevoltant(60),
      Monsters.Skills.Group(`3 à 6 [2 + 1d4]`),
    ],
    speed: 2,
  },
  {
    attacks: [
      `80 % : applique Radiations III`,
      `60 % : applique Poison D pour 12 tours`,
      `80 % : transmets le Petit Mal`,
      `30 % : transmets la Putride`,
      `15 % : transmets la Rage`,
    ],
    damage: [`4 + 1d6`, ``, ``],
    hit: [90, 0, 0],
    initiative: 4,
    name: `Porteur de maladies`,
    passives: [Monsters.Skills.Virevoltant(60)],
    speed: 4,
  },
  {
    attacks: [
      `60 % : applique Poison D+ pour 12 tours`,
      `40 % : transmets la Putride`,
      `20 % : transmets la Rage`
    ],
    damage: [`3 + 1d6`, ``, ``],
    hit: [75, 0, 0],
    initiative: 4,
    name: `Infecte rongeur`,
    passives: [Monsters.Skills.Group(`2 à 5 [1 + 1d4]`)],
    speed: 3,
  },
  {
    abilities: [Monsters.Skills.Charge(`3`, `6`)],
    armor: 1,
    damage: [`6 + 1d6`, ``, ``],
    hit: [70, 0, 0],
    initiative: 2,
    name: `Mut-cerf`,
    passives: [`Armure à la tête : 2`],
    speed: 4,
  },
  {
    abilities: [
      `Jet de soie : 50 % de chances d'aveugler la cible`,
      `Sol gluant : réduit la vitesse de déplacement de la cible de moitié dans la zone affectée`,
    ],
    armor: 1,
    attacks: [`90 % : applique Poison M- pour 24 tours`],
    damage: [`6 + 1d6`, ``, ``],
    hit: [85, 0, 0],
    initiative: 2,
    name: `Mut-araignée`,
    speed: 4,
  },
  {
    armor: 5,
    attacks: [`Applique Poison D pour 48 tours`],
    damage: [`8 + 1d6`, ``, ``],
    hit: [90, 0, 0],
    initiative: 4,
    name: `Mut-scorpion géant`,
    passives: [`Armure à la tête : 2`],
    speed: 4,
  },
  {
    abilities: [Monsters.Skills.Tunnel],
    armor: 2,
    damage: [`10 + 1d4`, ``, ``],
    hit: [110, 0, 0],
    initiative: 2,
    name: `Mut-taupe alpha`,
    passives: [
      `Groupe : se trouve généralement avec un groupe de 2 à 7 Mut-taupe [1 + 1d6]`,
      Monsters.Skills.Chef('Mut-taupes', 2),
    ],
    speed: 3,
  },
  {
    armor: 3,
    damage: [`10 + 1d8`, ``, ``],
    hit: [85, 0, 0],
    name: `Goun tao`,
    show: [
      `50 % de chances d'effrayer les individus proches`,
      `Chances d'effrayer augmentées de 30 % si furtif`
    ],
    speed: 5,
  },
  {
    armor: 1,
    attacks: [
      `Applique Poison M- pour 24 tours`,
      `Applique Poison D+ pour 12 tours`,
    ],
    damage: [`16 + 1d8`, ``, ``],
    hit: [110, 80, 95],
    name: `Mut-mouche géante`,
    passives: [Monsters.Skills.Virevoltant(50)],
    speed: 3,
  },
  {
    abilities: [Monsters.Skills.Enfouissement],
    armor: 8,
    attacks: [`80 % : applique Radiations III`],
    damage: [`8 + 1d6`, ``, ``],
    hit: [85, 0, 0],
    initiative: 2,
    name: `Mut-tortue`,
    passives: [`Pas d'armure à la tête`],
    speed: 2,
  },
  {
    abilities: [Monsters.Skills.Saut(`5x1d6`)],
    armor: 1,
    attacks: [`60 % : applique Poison D pour 12 tours`],
    damage: [`8 + 1d6`, ``, ``],
    hit: [90, 0, 0],
    initiative: 3,
    name: `Traqueur nocturne`,
    passives: [`Nyctalope : voit aussi bien de nuit`],
    speed: 4,
  },
  {
    armor: 3,
    attacks: [
      `90% : applique Poison D+ et M- pour 6 tours`,
      `60% : transmets la rage`,
    ],
    damage: [`10 + 1d6`, ``, ``],
    hit: [105, 0, 0],
    initiative: 4,
    name: `Basilisk`,
    passives: [
      Monsters.Skills.CorpsDePoison(`IV`, 3, `D+ et M-`),
      `Armure dans le dos : 5`,
    ],
    speed: 4,
  },
  {
    armor: 3,
    damage: [`10 + 1d6`, ``, ``],
    hit: [110, 0, 0],
    initiative: 6,
    name: `Mut-gecko`,
    passives: [Monsters.Skills.Group(`5 à 8 [4 + 1d4]`)],
    speed: 4,
  },
  {
    abilities: [`Traînée empoisonnée : libère du poison qui se répand dans les eaux proches et qui, à chaque fin de tour, a 80 % de chances d'appliquer Poison D pour 12 tours`],
    attacks: [`Les attaques sont en PM et sont des choc mentaux : étourdit pendant 2 tours`],
    damage: [`8 + 4x1d8`, ``, ``],
    hit: [0, 65, 0],
    initiative: 5,
    name: `Mut-poisson`,
    passives: [`Frémissant: réduit les chances d'être touché de 50 %`],
    speed: 5,
  },
  {
    abilities: [Monsters.Skills.Saut(6)],
    armor: 2,
    attacks: [`Applique Saignements IV permanent`],
    damage: [`10 + 1d4`, ``, ``],
    hit: [85, 0, 0],
    initiative: 6,
    name: `Molosse`,
    passives: [Monsters.Skills.Group(`3 à 6 [2 + 1d4]`)],
    speed: 4,
  },
  {
    abilities: [Monsters.Skills.Piétinement],
    armor: 4,
    attacks: [`75 % : renverse la cible au sol`],
    damage: [`12 + 1d8`, ``, ``],
    hit: [95, 0, 0],
    initiative: 2,
    name: `Bergaul`,
    speed: 5,
  },
  {
    abilities: [Monsters.Skills.HurlementStrident],
    armor: 5,
    attacks: [`Applique Saignements II permanent`],
    damage: [`12 + 1d6`, ``, ``],
    hit: [120, 0, 0],
    name: `Mut-ours`,
    show: [`90% : effraie les personnes proches`],
    speed: 4,
  },
  {
    abilities: [
      Monsters.Skills.HurlementStrident,
      `Cri à la lune : les personnes proches doivent faire un test de Sang froid, elles seront effrayées si elles échouent`
    ],
    armor: 5,
    attacks: [`Applique Saignements IV permanent`],
    damage: [`14 + 1d4`, ``, ``],
    hit: [105, 0, 0],
    initiative: 8,
    name: `Mut-garou`,
    speed: 7,
  },
  {
    abilities: [`Rets : envoie des rets sur une cible et l'immobilise sur sa position`],
    armor: 3,
    attacks: [`Applique Poison D+ pour 12 tours`],
    damage: [`10 + 1d6`, ``, ``],
    hit: [85, 0, 0],
    initiative: 4,
    name: `Reine mut-araignée`,
    show: [`Effraie les individus proches`],
    speed: 5,
  },
  {
    abilities: [`Constriction : immobilise une cible et augmente sa Fatigue de 1 par tour d'étreinte. La cible ne peut plus respirer quand elle est fatiguée`],
    armor: 6,
    attacks: [`Applique Poison IV pour 6 tours, type D+`],
    damage: [`10 + 1d6`, ``, ``],
    hit: [110, 0, 0],
    initiative: 6,
    name: `Mut-serpent`,
    show: [`Effraie les individus proches`],
    speed: 6,
  },
  {
    armor: 7,
    damage: [`12 + 1d10`, ``, ``],
    hit: [90, 0, 0],
    initiative: 2,
    name: `Mut-crocodile`,
    show: [`Effraie les individus proches`],
    speed: 4,
  },
  {
    abilities: [`Projections d'encre : applique un malus de 80 % sur les tests qui nécessite une visibilité dans la zone`],
    armor: 4,
    damage: [`15 + 1d8`, ``, ``],
    hit: [70, 0, 0],
    initiative: 4,
    name: `Mut-requin`,
    show: [`Effraie les individus proches`],
    speed: 12,
  },
  {
    abilities: [
      `Jet de bave : réduit l'Initiative de 4 et la vitesse de déplacement de la cible de 33 %, cumulable`,
      `Étreinte de poison : s'enroule autour d'une cible immobile et la serre ce qui lui applique trois occurrences de Poison V pour 6 tours (catégorie D+)`,
      Monsters.Skills.Tunnel,
    ],
    initiative: 4,
    name: `Ver de vase`,
    passives: [Monsters.Skills.CorpsDePoison(`V`, 6, `D+`)],
    speed: `2 (6 en souterrain)`,
  },
];

export default data.map((i) => ({
  ...i,
  types: [Monsters.Type.BEAST],
}));
