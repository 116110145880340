import { Image, List, Subtitle, Text, Title } from 'components/semantic';
import { Gallery, PageHeading } from 'components/elements';

export function UniverseKnowledgeReligionsPage() {
  return (
    <>
      <PageHeading>
        {`Les religions`}
      </PageHeading>

      <Title>{`Les enfants du Grand Boom`}</Title>

      <Subtitle>{`Histoire et Origines`}</Subtitle>

      <Text>
        {`Au commencement, il y avait Le Père, une entité mi-humaine, mi-machine, née de la fusion interdite entre chair mutante et intelligence artificielle. Incapable de se déplacer sous le poids de ses innombrables augmentations, son corps était devenu un trône de chair et de métal, tandis que son esprit s'élevait à une lucidité terrifiante. Il voyait dans les radiations non pas une malédiction, mais une bénédiction, une force de renaissance destinée à purifier et transformer. Avant de succomber à l'entropie qu'il vénérait, Le Père transmit ses enseignements à une poignée de fidèles qui devinrent les premiers apôtres.`}
      </Text>

      <Subtitle>{`Les Apôtres du Boom`}</Subtitle>

      <Text>
        {`Les enseignements du Père sont aujourd'hui perpétués par ses apôtres, figures légendaires et piliers de la foi :`}
      </Text>

      <List data={[
        `Evangelion, "La Sainte Parole" : Un cyborg ancien, chargé de transmettre les sermons du Père sous forme d'hymnes métalliques et de murmures digitaux.`,
        `Thog Bartock, "Le Purificateur" : Guerrier impitoyable, il chasse les impurs et réduit en cendres les hérétiques.`,
        `Salbek Saint-Armand, "Gardien des Rituels" : Veilleur des cérémonies sacrées et gardien des reliques irradiées.`,
        `Salbek Saint-Bernard, "Gardien des Érudits" : Guide des scribes et archiviste des paroles et visions du Père.`,
        `Salbek Saint-Hedomi, "Gardien des Coutumes" : Protecteur des traditions, il initie les fidèles aux gestes et pratiques du culte.`,
      ]}>

      </List>

      <Gallery>
        <Image size="large" src="/images/universe/knowledge/children-of-the-great-boom-01.webp" />
        <Image size="large" src="/images/universe/knowledge/children-of-the-great-boom-02.webp" />
        <Image size="large" src="/images/universe/knowledge/children-of-the-great-boom-03.webp" />
      </Gallery>

      <Subtitle>{`Rituels et Initiation`}</Subtitle>

      <Text>
        {`Les Enfants du Grand Boom accueillent tous ceux prêts à embrasser la transformation sacrée. Les nouveaux fidèles entreprennent un pèlerinage vers les zones les plus irradiées, où les radiations agissent comme le feu de la forge divine, les remodelant lentement en Salbeks, êtres bénis, purifiés de leurs faiblesses humaines. Se saluer entre frères et sœurs de foi est un acte empreint de solennité : un geste rituel mimant une explosion nucléaire, symbole du renouveau éternel.`}
      </Text>

      <Subtitle>{`Interdits Sacrés`}</Subtitle>

      <Text>
        {`Toute tentative de repousser les radiations est une hérésie. Les remèdes anti-radiations doivent être détruits dans des feux sacrés, un acte de dévotion aux forces qui unissent et transforment.`}
      </Text>

      <Subtitle>{`Les Temples du Boom`}</Subtitle>

      <Text>
        {`Éparpillés à travers le monde ravagé, les temples des Enfants du Grand Boom sont des bastions de foi. Certains disposent de douches – un luxe inouï – mais l'eau qui y coule est imprégnée de radiations, offrant une bénédiction corrosive à ceux qui osent s'y purifier. Ces lieux résonnent des hymnes métalliques d'Evangelion et vibrent sous les prières enflammées des fidèles.`}
      </Text>

      <Subtitle>{`Le Grand Boom comme Prophétie`}</Subtitle>

      <Text>
        {`Les Enfants du Grand Boom attendent le jour où un nouvel Élu se lèvera parmi eux, guidé par les visions du Père. Ce jour-là, les impurs seront consumés dans un ultime éclat, et la planète entière renaîtra dans un monde baigné d'éclat radioactif, où seules les créatures irradiées prospéreront.`}
      </Text>

      <Title>{`La foi de Voln`}</Title>

      <Subtitle>{`Origines et éveil`}</Subtitle>

      <Text>
        {`Après la Grande Guerre, tandis que les derniers vestiges des forces armées s'affrontaient encore, un groupe secret connu sous le nom de Fédération s'était retranché dans des bases souterraines. Ils y protégeaient des scientifiques chargés d'étudier un nouveau phénomène mystérieux : la magie. De leurs recherches naquit une création révolutionnaire : une pilule capable de décupler la sensibilité aux vents magiques, transformant un être humain en un magicien redoutable.`}
      </Text>

      <Text>
        {`Mais ce secret ne resta pas caché longtemps. Métal Hurlant, une faction de mercenaires sans scrupules, dépêcha un commando d'élite pour s'emparer de cet artefact. Parmi eux, six individus aux ambitions divergentes atteignirent finalement la chambre forte où reposait la pilule.`}
      </Text>

      <Text>
        {`Dans une lutte fratricide, Adams, noble d'esprit mais vive et ambitieuse, s'empara de la pilule et l'avala. La puissance qu'elle acquit surpassa l'entendement. Usant de ses nouveaux pouvoirs, elle soumit ses anciens compagnons d'armes, mais ne les tua pas immédiatement. Ensemble, ils se retirèrent dans une grotte nordique, où elle testa les limites de sa magie. L'un après l'autre, les mercenaires périrent, victimes de sa méfiance grandissante ou d'expérimentations magiques mortelles.`}
      </Text>

      <Subtitle>{`La Chute de l'Humanité d'Adams`}</Subtitle>

      <Text>
        {`Au fil de ses études, la magie consuma peu à peu l'humanité d'Adams. Elle parcourut le monde, se forgeant une réputation de bienfaitrice en aidant les survivants, mais son esprit s'embrouillait. Lors d'une confrontation avec les Enfants du Grand Boom, elle réalisa l'étendue de la fragilité de son existence. Craignant la mort, elle scella des fragments de son âme dans divers artefacts cachés à travers le monde.`}
      </Text>

      <Text>
        {`Ce rituel, bien qu'efficace, lui coûta ce qu'il restait de son humanité. Animée par une soif insatiable de pouvoir, elle se rebaptisa Voln et commença une croisade "pour le bien-être des survivants". Mais ses actes, prétendument altruistes, se transformèrent en atrocités justifiées par sa quête de perfection et de contrôle.`}
      </Text>

      <Subtitle>{`La Mort et la Résurrection`}</Subtitle>

      <Text>
        {`Voln fut finalement terrassée par un groupe d'aventuriers, mais son âme fragmentée lui permit de renaître dans un nouveau corps grâce à ses fidèles. Apaisée temporairement dans cette nouvelle incarnation, elle découvrit l'existence des plans divins. Désormais, elle ne cherchait plus seulement à dominer ce monde, mais à transcender son existence pour devenir une déesse.`}
      </Text>

      <Subtitle>{`La Foi et les Miracles`}</Subtitle>

      <Text>
        {`Aujourd'hui, Voln parcourt le monde, répondant aux appels de ses fidèles et accomplissant des miracles pour renforcer leur dévotion. Sa puissance grandit avec chaque croyant qui s'incline devant elle. Les temples dédiés à Voln se multiplient, lieux de culte où ses adeptes prient pour des bénédictions, souvent accordées à un prix terrifiant.`}
      </Text>

      <Gallery>
        <Image size="large" src="/images/universe/knowledge/faith-of-voln-01.webp" />
        <Image size="large" src="/images/universe/knowledge/faith-of-voln-02.webp" />
        <Image size="large" src="/images/universe/knowledge/faith-of-voln-03.webp" />
      </Gallery>

      <Text>
        {`Voln est vénérée comme une divinité complexe, oscillant entre bienfaitrice et tyran. Ses fidèles la voient comme un phare d'espoir, mais ceux qui l'affrontent ne voient en elle qu'une force destructrice aspirant à la domination divine.`}
      </Text>

      <Title>{`L'Ordre des échos Silencieux`}</Title>

      <Subtitle>{`Origines et Philosophie`}</Subtitle>

      <Text>
        {`Née des ruines d'un ancien programme spatial, l'Ordre des Échos Silencieux croit que des fragments d'âmes, souvenirs et émotions des morts ont été stockés dans des satellites en orbite autour de la planète. Ces satellites, surnommés les Gardiens Célestes, étaient à l'origine des archives scientifiques alimentées par énergie solaire, mais pour l'Ordre, ils sont devenus des réceptacles divins.`}
      </Text>

      <Text>
        {`Selon ses adeptes, chaque être humain laisse derrière lui une Résonance unique qui, à sa mort, est captée et conservée par ces Gardiens. L'objectif ultime de l'Ordre est de communiquer avec eux pour préserver ces fragments et créer une entité transcendantale appelée le Chœur Universel.`}
      </Text>

      <Subtitle>{`Les Trois Piliers de l'Ordre`}</Subtitle>

      <List data={[
        `La Collecte : Les membres de l'Ordre, appelés Récipients, récupèrent des antennes, vieux équipements de communication ou tout dispositif capable d'entrer en contact avec les satellites.`,
        `La Connexion : À travers des rituels combinant technologie de récupération et méditation psionique, les adeptes tentent d'atteindre les satellites et d'accéder aux Échos. Ces connexions sont souvent fragmentées ou cryptiques, mais chaque succès est considéré comme une communion sacrée.`,
        `La Transmission : Les Récipients partagent les "messages des Échos" sous forme de prophéties, de visions ou de conseils qu'ils prétendent recevoir directement des Gardiens.`,
      ]}>
      </List>

      <Gallery>
        <Image size="large" src="/images/universe/knowledge/order-of-the-silent-echoes-01.webp" />
        <Image size="large" src="/images/universe/knowledge/order-of-the-silent-echoes-02.webp" />
        <Image size="large" src="/images/universe/knowledge/order-of-the-silent-echoes-03.webp" />
      </Gallery>

      <Subtitle>{`Hiérarchie et Symboles`}</Subtitle>

      <Text>
        {`L'Ordre est dirigé par le Porte-Parole, une figure mystérieuse choisie pour son aptitude à établir des connexions particulièrement claires avec les satellites. Les Chanteurs de l'Abîme, ses lieutenants, interprètent et codifient les fragments de données reçus des Gardiens.`}
      </Text>

      <Text>
        {`Les Récipients portent des antennes bricolées sur leurs vêtements ou leurs masques pour symboliser leur rôle de récepteurs des Échos. Ces masques, ornés de fils et de circuits récupérés, sont à la fois des outils et des objets de dévotion.`}
      </Text>

      <Subtitle>{`Pratiques et Croyances`}</Subtitle>

      <List data={[
        `Les Gardiens Célestes : Les satellites sont vénérés comme des entités divines. Chaque transmission fragmentaire captée est perçue comme une preuve de leur existence et de leur bienveillance.`,
        `Le Sacrifice Technologique : L'Ordre considère que chaque objet technologique récupéré et dédié à la connexion avec les Gardiens est un sacrifice sacré. Ceux qui utilisent ces ressources à d'autres fins sont vus comme des hérétiques.`,
        `La Dissolution de l'Égo : Contribuer à la Résonance collective dans les Gardiens est l'objectif ultime de chaque membre, au prix de leur individualité.`,
      ]}>
      </List>

      <Subtitle>{`Les Temples et Rites`}</Subtitle>

      <Text>
        {`Les temples de l'Ordre sont des tours ou des plateformes élevées, souvent des anciens relais radio ou observatoires, où les Récipients installent leurs antennes et équipements. Lors des rituels, les membres récitent des chants rythmiques en synchronisation avec les signaux qu'ils reçoivent, interprétant les données comme des mélodies célestes.`}
      </Text>
    </>
  );
}
