import { Element, Type, Subtype } from 'data/metadata';

const data = [
  {
    effect: `Dépendance 90%`,
    name: `Addicto`,
    note: `Une substance conçue en laboratoire pour soumettre des populations en jouant avec l'addiction des gens
    Se présente sous la forme de cachets dans une petite boîte`,
    value: 68,
    weight: 0.05,
  },
  {
    effect: `Guérit des poisons les plus communs : D, D+, H et K
    Des chances d'avoir des vomissements`,
    name: `Antidote`,
    note: `Une boisson dans une petite bouteille en verre ou en plastique
    Fabriquée à partir d'herbes médicinales, la recette est plutôt connue`,
    value: 11,
    weight: 0.5,
  },
  {
    effect: `Soigne 10 points de dégâts sur un membre du corps tant qu'il est bandé`,
    name: `Bandage`,
    note: `Nécessite de réussir un test de Biologie si l'action est faite en urgence`,
    value: 6,
    weight: 0.05,
  },
  {
    effect: `Donne une chance de faire repartir un cœur arrêté
    Nécessite un test de Biologie
    Consomme 80 PE par activation`,
    name: `Défibrillateur`,
    note: `Les défibrillateurs automatiques donnent un bonus sur le test de Biologie`,
    value: 92,
    weight: 4,
  },
  {
    name: `Fiole de poison`,
    note: `Une fiole de poison
    Regarder les différents types de poisons pour déterminer duquel il s'agit`,
    value: 9,
    weight: 0.5,
  },
  {
    effect: `Peut supprimer les saignements sur les bras ou les jambes
    Nécessite un test de Biologie
    Des risques de nécrose sur le membre et d'arrêt cardiaque`,
    name: `Garrot`,
    note: `Peut également être réalisé à partir d'objet communs comme une ceinture`,
    value: 8,
    weight: 0.1,
  },
  {
    effect: `Guérit de tous les poisons
    Radiations -50
    Vigueur -50% pendant 24 heures`,
    name: `Intesticline`,
    note: `Une pillule miracle inventée en laboratoire, plutôt rare à trouver`,
    value: 65,
    weight: 0.05,
  },
  {
    effect: `Initiative +5 pendant 1 minute
    Dépendance 95%`,
    name: `J-Speed`,
    note: `La drogue la plus commune des terres dévastées, utilisées par les raiders sur la route`,
    value: 3,
    weight: 0.05,
  },
  {
    effect: `Récupère 3 points de Magie
    Réveille de l'inconscience magique`,
    name: `Magipak`,
    note: `Une seringue inventée en laboratoire, plutôt rare à trouver`,
    value: 28,
    weight: 0.05,
  },
  {
    effect: `Acuité, Concentration et Sang Froid +40% pendant 1 minute
    Dépendance 60%`,
    name: `Menta-pill`,
    note: `Une boîte de pilules utilisées par les soldats à la guerre pour être plus efficaces lors des opérations tactiques`,
    value: 65,
    weight: 0.05,
  },
  {
    effect: `Ignore l'effet des blessures pendant 1 minute
    Dépendance 45%`,
    name: `Nervocut`,
    note: `Une boîte de pilules utilisées par les soldats à la guerre pour ignorer leurs blessures`,
    value: 86,
    weight: 0.05,
  },
  {
    effect: `Force, Vigueur et Volonté +40% pendant 2 minutes
    Dépendance 45%`,
    name: `Power-X`,
    note: `Une boîte de pilules utilisées par les soldats à la guerre pour résister aux explosions`,
    value: 41,
    weight: 0.05,
  },
  {
    effect: `Permet de récupérer plus rapidement des blessures contondantes`,
    name: `Pommade anti-inflammatoire`,
    note: `Apaise les inflammations`,
    value: 7,
    weight: 0.05,
  },
  {
    effect: `Soigne 50 dégâts sur un membre du corps
    Nécessite un test de Biologie`,
    name: `Soinringue`,
    note: `Une seringue inventée en laboratoire, plutôt rare à trouver`,
    value: 62,
    weight: 0.1,
  },
  {
    effect: `Supprime les saignements sur un membre du corps à l'aide d'une opération
    Nécessite un test de Biologie`,
    name: `Trousse de chirurgie`,
    note: `Une trousse assez complète qui contient scalpel, du fil et de quoi recoudre, des désinfectants, ...`,
    value: 95,
    weight: 10,
  },
];

export default data.map((i) => ({
  ...i,
  element: Element.ITEM,
  subtype: Subtype.General.MEDICAL,
  type: Type.Item.GENERAL,
}));
