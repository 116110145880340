
import localization from 'data/rules/localization';
import precisionModifiers from 'data/rules/precisionModifiers';
import { List, Quote, Subtitle, Table, Text, Title } from 'components/semantic';
import { PageHeading } from 'components/elements';
import hitPointsData from 'data/rules/hitPoints';

export function ManualDamagePage() {
  return (
    <>
      <PageHeading>
        Touche et dégâts
      </PageHeading>

      <Title>Toucher la cible</Title>

      <Text>
        {`Avant de pouvoir blesser une cible, il faut d'abord la toucher. Pour ce faire, il faut lancer un dé sous la compétence de l'arme utilisée. Il est possible de viser une partie du corps en particulier, mais cela augmente la difficulté du jet.`}
      </Text>

      <Subtitle>Localisation</Subtitle>

      <Quote>
        {`Si une attaque n'est pas localisée, il faut inverser le dé des dizaines et celui des unités pour déterminer la localisation de l'attaque.`}
      </Quote>

      <Table data={localization}></Table>

      <Subtitle>La precision</Subtitle>

      <Text>
        {`Des modificateurs de précision existent selon la portée de l'arme mais également selon la situation :`}
      </Text>

      <Table data={precisionModifiers}></Table>

      <Title>Les dégâts</Title>

      <Subtitle>Dégâts infligés</Subtitle>

      <Text>
        {`Afin de déterminer le montant des dégâts infligés, il faut regarder les détails de l'arme ou du sort utilisé. Voici tout ce qui peut s'ajouter aux dégâts :`}
      </Text>

      <List data={[
        'Montant de dégâts de base (une valeur fixe)',
        `Jet de dé (une valeur aléatoire, il faut lancer le dé demandé et le multiplier par le facteur si précisé sur l'arme ou le sort)`,
        `Degré de réussite (voir le glossaire, il faut le multiplier par le facteur si précisé sur l'arme ou le sort)`,
      ]} />

      <Quote>
        {`Lors d'un succès critique sur le test de toucher, il faut encore ajouter le degré de réussite au montant des dégâts infligés.`}
      </Quote>

      <Text>
        {`Ensuite, de ces dégâts sera déduit l'armure de la cible sur le membre touché, cela inclut son armure naturelle ainsi que l'armure portée.`}
      </Text>

      <Subtitle>Conséquences</Subtitle>

      <Text>
        {`Lorsqu'un personnage reçoit des dégâts, il perd immédiatement un point de mental. Certaines blessures peuvent également engendrer des conséquences supplémentaires, comme l'application d'un poison ou de radiations, ou encore un saignement ou une hémorragie.`}
      </Text>

      <Text>
        {`Ensuite, lorsque les points de vie d'un membre du corps du personnage doivent tomber à 0 ou moins, ils restent à 0, mais le personnage gagne un point de Psychose et a une chance de perdre le membre du corps équivalente à la valeur négative théorique.`}
      </Text>

      <Quote>
        {`Par exemple si un personnage a 34 points de vie restant sur son bras et prend un tir de sniper qui lui inflige 84 dégâts, il aura désormais 0 points de vie sur le bras mais le montant négatif théorique est de -50. Le joueur tire 1d100 et sur un score de 50 ou moins, le personnage perd l'usage de son bras.`}
      </Quote>

      <Text>
        {`Dès que les points de vie d'un membre du corps tombent à 0, des effets supplémentaires s'appliquent:`}
      </Text>

      <Table data={hitPointsData}></Table>

      <Text>
        {`Suite à cela, même si les points de vie sur ce membre augmentent, les effets persistent tant que le personnage ne se sera pas reposé.`}
      </Text>
    </>
  );
}
