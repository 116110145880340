import { Spells } from 'data/metadata';

const data = Object.freeze([
  {
    additionalEffects: [
      `Effet : ${CHARACTER.SKILLS.MASTERY.ACUITY} -20 (vue)`,
      `Effet : Actions -10 % vers cette lumière`,
    ],
    cost: 2,
    description: `Change une source de lumière afin de gêner une personne. Les effets ne fonctionnent que s'il s'agit d'une intensification dans un endroit sombre ou d'une atténuation dans un endroit lumineux.`,
    name: `Changement d'intensité lumineuse`,
    types: [
      Spells.Types.CAST,
      Spells.Types.CAST_CHANNEL,
    ],
  },
  {
    additionalEffects: [
      `Effet : taille x 2 ou taille ÷ 2`,
      `Surcharge : taille x 3 ou taille ÷ 3`,
    ],
    cost: 2,
    description: `Augmente ou réduit la taille d'une personne.`,
    name: `Changement de taille`,
    requirements: [`contact`],
    types: [
      Spells.Types.CAST,
      Spells.Types.CAST_CHANNEL,
    ],
  },
  {
    cost: 2,
    description: `Détecte la présence de radioactivité dans un être vivant ou dans une petite zone.`,
    name: `Détection des radiations`,
    requirements: [`contact`],
    types: [Spells.Types.CAST],
  },
  {
    additionalEffects: [`Surcharge : permet de détecter de quel type de poison est-ce qu'il s'agit`],
    cost: 2,
    description: `Détecte la présence de poison dans le corps d'une personne.`,
    name: `Détection du poison`,
    requirements: [`contact`],
    types: [Spells.Types.CAST],
  },
  {
    additionalEffects: [`Attention : canaliser un sort ne permet pas de se déplacer naturellement`],
    cost: 2,
    description: `Fait briller un objet, ce qui lui permet d'éclairer dans l'obscurité. Il est également possible de gêner l'utilisation d'un objet avec ce sort, tel que la lecture d'un livre ou l'utilisation d'un tableau de bord.`,
    name: `Irradiance`,
    types: [
      Spells.Types.CAST,
      Spells.Types.CAST_CHANNEL,
    ],
  },
  {
    additionalEffects: [
      `Effet : augmente la résistance au froid et au chaud de 3°`,
      `Surcharge : augmente la résistance au froid et au chaud de 6°`,
    ],
    cost: 2,
    description: `Adapte la peau d'une personne afin de réduire l'impact de la température sur son organisme.`,
    name: `Peau de lézard`,
    requirements: [
      `contact`,
      `relique (peau de lézard conservée)`,
    ],
    types: [
      Spells.Types.CAST,
      Spells.Types.CAST_CHANNEL,
    ],
  },
  {
    additionalEffects: [
      `Effet : blessures infligées à mains nues +2`,
      `Effet : armure naturelle +4`,
      `Surcharge : double le bonus d'armure et de blessures`,
    ],
    cost: 2,
    description: `Durcit la peau d'une personne afin d'en augmenter sa résistance.`,
    name: `Peau d'écorce`,
    requirements: [`contact`],
    types: [
      Spells.Types.CAST,
      Spells.Types.CAST_CHANNEL,
    ],
  },
  {
    additionalEffects: [
      `Effet : ${CHARACTER.SKILLS.MASTERY.ACUITY} +15`,
      `Surcharge : réduit les malus d'obscurité ou de luminosité de moitié`,
      `Surcharge : double la durée du souffle sous l'eau`,
    ],
    cost: 2,
    description: `Adapte la morphologie d'une personne afin de le doter de sens plus aiguisés.`,
    name: `Sens accrus`,
    requirements: [`contact`],
    types: [
      Spells.Types.CAST,
      Spells.Types.CAST_CHANNEL,
    ],
  },
  {
    cost: 4,
    description: `Dissipe la présence d'un poison précis de d'un être vivant.`,
    name: `Dissipation du poison`,
    requirements: [
      `contact`,
      `relique (extrait du poison recherché)`,
    ],
    types: [Spells.Types.CAST],
  },
  {
    additionalEffects: [
      `Surcharge : Lui faire croire qu'elle est en mouvement alors qu'elle ne l'est pas permet de perturber le sens cinétique de son oreille interne`,
      `Surcharge : ${CHARACTER.SKILLS.MASTERY.AGILITY} -40`,
      `Surcharge : 80% de chances de provoquer la nausée`,
      `Surcharge : 40% de chances d'infliger l'état de santé « vomissements »`,
    ],
    cost: 4,
    description: `Change la lumière qui arrive dans les yeux d'une personne de sorte de lui faire voir des images illusoires.`,
    name: `Illusions oculaires`,
    types: [
      Spells.Types.CAST,
      Spells.Types.CAST_CHANNEL,
    ],
  },
  {
    additionalEffects: [
      `Effet en plein jour : ${CHARACTER.SKILLS.MASTERY.ACUITY} (vue) -25 pour 1d4 tours`,
      `Effet dans un endroit sombre : aveugle pour 1d4 tours`,
      `Surcharge : durée augmentée à 1d12 tours`,
    ],
    cost: 4,
    description: `Crée un flash lumineux tellement intense qu'il aveugle les personnes qui le voient.`,
    name: `Flash`,
    types: [Spells.Types.CAST],
  },
  {
    additionalEffects: [`Surcharge : crée une deuxième barrière en parallèle, de sorte que la lumière de l'une se transmette à l'autre et vice versa, créant un espace invisible entre les deux barrières`],
    area: `1 m²`,
    cost: 4,
    description: `Crée magiquement une barrière intangible qui renvoi la lumière ce qui donne l'effet d'un miroir, pratique pour se cacher derrière.`,
    name: `Miroir`,
    types: [
      Spells.Types.CAST,
      Spells.Types.CAST_CHANNEL,
    ],
  },
  {
    additionalEffects: [`Surcharge : dégâts augmentées à 40 + 8x1d8`],
    cost: 4,
    damage: `25 + 5x1d8`,
    description: `Concentre de l'énergie lumineuse et la projette vers une personne afin de l'incinérer. Cet effet peut atteindre de longues distances.`,
    name: `Rayon laser`,
    types: [Spells.Types.CAST],
  },
  {
    additionalEffects: [`Surcharge : double la dose de la drogue en ce qui concerne la gestion des dépendances`],
    cost: 4,
    description: `Applique magiquement les effets d'une drogue sur une personne.`,
    name: `Simulation de drogue`,
    requirements: [
      `contact`,
      `relique (échantillon de la drogue)`,
    ],
    types: [
      Spells.Types.CAST,
      Spells.Types.CAST_CHANNEL,
    ],
  },
  {
    additionalEffects: [
      `Effet : réduit les chances de contracter une maladie de moitié`,
      `Effet : Endurance -1 pendant 24 heures`,
      `Surcharge : réduit la durée de certaines maladies dem moitié`,
      `Surcharge : Endurance -3 supplémentaires pendant 24 heures`,
    ],
    cost: 4,
    description: `Renforce le système immunitaire d'une personne afin de lutter contre les infections et les maladies.`,
    name: `Stimulation immunitaire`,
    requirements: [`contact`],
    types: [
      Spells.Types.CAST,
      Spells.Types.CAST_CHANNEL,
    ],
  },
  {
    additionalEffects: [
      `Effet : ${CHARACTER.SKILLS.MASTERY.AGILITY} (discrétion) +75`,
      `Attention : canaliser un sort ne permet pas de se déplacer naturellement`,
    ],
    cost: 6,
    description: `Le corps d'un être ne renvoie plus la lumière, l'empêchant ainsi d'être vu.`,
    name: `Invisibilité`,
    requirements: [`contact`],
    types: [
      Spells.Types.CAST,
      Spells.Types.CAST_CHANNEL,
    ],
  },
  {
    cost: 6,
    description: `Déplace le visuel d'une scène à un autre endroit afin de leurrer des personnes.`,
    name: `Mirage`,
    types: [
      Spells.Types.CAST,
      Spells.Types.CAST_CHANNEL,
    ],
  },
  {
    cost: 6,
    description: `Permet de recréer une scène du passé dans un endroit clos afin de savoir ce qui s'y est déroulé.`,
    name: `Rémanence restituée`,
    requirements: [`lieu clos`],
    types: [Spells.Types.CAST],
  },
  {
    additionalEffects: [
      `Effet : ressoude un membre perdu depuis moins de 15 secondes`,
      `Attention : relâcher l'invocation annule les effets`,
    ],
    cost: 6,
    description: `Insuffle de la vie à un être vivant afin de lui redonner des forces.`,
    name: `Ressouder un membre`,
    requirements: [`contact`],
    types: [Spells.Types.SUMMON],
  },
  {
    additionalEffects: [
      `Effet : sur la cible, Mental à 0, Psychose +3, Endurance -1 définitivement, Vigueur -5 définitivement`,
      `Effet : sur le lanceur, Psychose +1, Endurance -1 définitivement, Vigueur -10 définitivement`,
    ],
    cost: 6,
    description: `Ramène à la vie un être vivant qui est décédé depuis moins de 15 secondes.`,
    name: `Résurrection`,
    requirements: [`contact`],
    types: [Spells.Types.CAST],
  },
  {
    area: `10 m²`,
    cost: 6,
    description: `Repère les formes de vie humanoïdes ou animales dans les alentours.`,
    name: `Sens de la vie`,
    types: [
      Spells.Types.CAST,
      Spells.Types.CAST_CHANNEL,
    ],
  },
  {
    additionalEffects: [
      `Effet : supprime les saignements sur un membre`,
      `Effet : Mental -1 et Fatigue -1 à la cible`,
    ],
    cost: 6,
    description: `Cicatrise une plaie et empêche toute hémorragie.`,
    name: `Soins des plaies`,
    requirements: [`contact`],
    types: [Spells.Types.CAST],
  },
]);

export default data.map((i) => ({
  ...i,
  schools: [{ name: Spells.School.LIGHT_LIFE }],
}));
