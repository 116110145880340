import { Monsters } from 'data/metadata';

const data = [
  {
    attacks: [`Applique Poison H pour 24 tours`],
    death: [`Nuage de poison : libère un nuage de poison qui a 30% de chances d'appliquer Poison H pour 24 tours`],
    hit: [80, 60, 0],
    name: `Bulbododo`,
  },
  {
    attacks: [
      `Applique Radiations I`,
      `50% : applique Poison D pour 12 tours`,
    ],
    damage: [`4 + 1d8`, ``, ``],
    hit: [60, 0, 0],
    name: `Spéléla`,
    speed: 1,
  },
  {
    abilities: [`Spores : libère des spores aux alentours qui ont 80 % de chances d'appliquer Poison H pour 24 tours`],
    attacks: [`Applique Poison D pour 6 tours`],
    damage: [`6 + DR + 1d6`, ``, ``],
    hit: [65, 45, 0],
    name: `Amyrexia`,
    passives: [Monsters.Skills.RégénérationRadioactive],
  },
  {
    abilities: [`Nuage de poison : libère un vaste nuage qui a 70% de chances d'appliquer Poison D+ pour 12 tours`],
    armor: 1,
    attacks: [`Applique Poison D pour 6 tours`],
    death: [`Spores empoisonnées : des spores transparentes ont 50% de chances d'appliquer Poison H à chaque tour pendant 12 tours`],
    hit: [75, 0, 0],
    name: `Mycognon`,
    speed: 2,
  },
  {
    abilities: [`"Nuage de poison": libère un vaste nuage qui a 80 % de chances d'appliquer Poison H pendant 36 tours`],
    name: `Chaxye`,
  },
  {
    name: `Mut-lotus`,
    passives: [
      `"Nuage de poison" : à la mort, libère un nuage de poison qui a 80% de chances d'appliquer Poison H pour 36 tours`,
      Monsters.Skills.AuraRadioactive('IV'),
    ],
  },
  {
    abilities: [`"Explosion de fumée": dégage une épaisse fumée verdâtre opaque`],
    armor: 2,
    attacks: [`85% de chances d'appliquer Saignements III pour 6 tours`],
    damage: [`9 + DR + 1d4`, ``, ``],
    hit: [80, 0, 0],
    initiative: 2,
    name: `Arracheur de coeurs`,
    skills: [
      { skill: `Agilité (en forêt)`, value: `120 %` },
    ],
    speed: 2,
  },
  {
    attacks: [
      `(PC) applique Saignements III permanent`,
      `(PM) 90 % : applique Poison M- pour 12 tours`,
      `(PM) 40 % : rend fou pendant 12 heures`
    ],
    damage: [`4 + 1d6`, ``, ``],
    hit: [90, 40, 0],
    name: `Syla`,
  },
  {
    armor: 2,
    attacks: [`70% : applique Poison D+ pour 36 tours`],
    damage: [`8 + 1d6`, ``, ``],
    hit: [90, 30, 0],
    name: `Fertiliseur`,
    passives: [`Venin addictif : les personnes touchées par le poison deviennent addict`],
    speed: 1,
  },
  {
    abilities: [
      Monsters.Skills.Tremblement,
      `Spores toxiques : libère un nuage qui applique Poison H et M- aux personnes proches pendant 12 tours`
    ],
    actions: `+1`,
    armor: 4,
    damage: [`7 + 1d10`, ``, ``],
    hit: [90, 70, 0],
    name: `Arbre béhémoth`,
    passives: [Monsters.Skills.Piétinement],
    show: [`Effraie les personnes proches`],
    speed: 4,
  },
  {
    abilities: [
      `Nuage de poison : libère un vaste nuage qui applique Poison D+ pour 10 tours`,
      `Groupe : se trouve généralement avec un groupe de 4 à 9 Mycognons [3 + 1d6]`,
      Monsters.Skills.Chef('Mycognons', 6),
    ],
    attacks: [`Applique Poison D+ pour 12 tours`],
    damage: [`5 + DR + 1d6`, ``, ``],
    death: [`Spores empoisonnées : des spores transparentes sont libérées qui applique poison H à chaque tour`],
    hit: [80, 0, 0],
    initiative: 2,
    name: `Clampignon`,
    speed: 2,
  },
  {
    abilities: [
      `Jet de poison : 80 % de chances d'appliquer Poison M- à la cible pour 6 tours`,
      `Barrage d'épines : inflige 8 + 1d6 aux personnes proches et leur applique Poison M- pour 12 tours, rechargement de 10 tours`
    ],
    actions: `+1`,
    armor: 4,
    damage: [`9 + 1d8`, ``, ``],
    hit: [90, 50, 0],
    initiative: 3,
    name: `Neolethid`,
  },
  {
    abilities: [`Étreinte douloureuse : tente d'attraper une cible et de la serrer, ce qui lui inflige 10 + 1d6 blessures`],
    actions: `+1`,
    armor: 6,
    attacks: [`Applique Poison, type H`],
    damage: [`9 + 1d6`, ``, ``],
    hit: [95, 70, 0],
    name: `Cactopus`,
  },
];

export default data.map((i) => ({
  ...i,
  types: [Monsters.Type.PLANT],
}));
