const aptitudes = [
  {
    name: `Adepte du gros calibre`,
    effect: [`Permet de porter les armes lourdes à une main`],
  },
  {
    name: `Archimage`,
    effect: [
      `Les variantes d'incantation ne génèrent plus de pénalités de Magie`,
    ]
  },
  {
    name: `Chanceux`,
    effect: [`Compétences +5`],
  },
  {
    name: `Cohésion du groupe`,
    effect: [
      `Lors des tests de compétences, si un allié proche a également l'aptitude 'Cohésion du groupe':`,
      `Il est possible d'utiliser son score de compétence pour faire le test`,
    ],
  },
  {
    name: `Esprit miroir`,
    effect: [
      `Lors des compétences en combat, il est possible d'utiliser le score de compétence d'une personne au choix à la place de celui du personnage`,
      `Attention: il ne faut pas connaître les scores de compétences des participants au combat, il faut lui donner le score et demander si l'action est réussie`,
    ],
  },
  {
    name: `Force démente`,
    effect: [
      `Double les dégâts infligés avec des armes qui utilisent la compétence Corps-à-corps`,
      `Les attaques ne peuvent plus être parées avec des objets : l'objet est brisé et le coup est quand même porté`,
    ],
  },
  {
    name: `Homme d'action`,
    effect: [
      `Lors des combats, après que chaque participant ait agit lors de la phase d'Initiative, octroie une action rapide supplémentaire`,
      `Temps +5`,
    ],
  },
  {
    name: `Joker`,
    effect: [
      `Double les dégâts infligés avec des armes qui utilisent la compétence Lancer et pièges`,
      `Lancer une grenade est une action rapide`,
    ],
  },
  {
    name: `Lucky-Luke`,
    requirements: [`Aptitude 'Tireur d'élite'`],
    effect: [
      `Les attaques avec la compétence ${CHARACTER.SKILLS.WEAPONS.LIGHT} sont maintenant des actions rapides`,
      `Après avoir dégainé une arme légère : applique les mêmes bonus qu'une attaque furtive`,
    ],
  },
  {
    name: `Oeil de lynx`,
    effect: [
      `Double les dégâts avec la compétence ${CHARACTER.SKILLS.WEAPONS.LIGHT} lors de tirs précis`,
      `Les tirs précis sont possibles en course`,
    ],
  },
  {
    name: `Main divine`,
    effect: [`Lors des jets qui déterminent le décès, les chances de décès sont réduites de 50%`],
  },
  {
    name: `Protecteur aguerri`,
    effect: [
      `Les parades sont des actions gratuites`,
      `Armure naturelle +5`,
    ],
  },
  {
    name: `Radioactivité cellulaire`,
    requirements: [`Radiations 800`],
    effect: [`Toute blessure se régénère immédiatement si elle n'est pas fatale`],
  },
  {
    name: `Sixième sens`,
    effect: [`Il est possible d'esquiver et parer les attaques furtives, même lors d'une incantation`],
  },
  {
    name: `Solitaire`,
    effect: [`Compétences +15 tant qu'il n'y a personne de connu à proximité`],
  },
  {
    name: `Souffleur de magie`,
    effect: [
      `Augmente les chances de réussite des sorts avec plusieurs écoles de magie : montant de la magie la plus élevée du sort ÷ nombre total d'écoles de magie du sort`,
      `Les sorts avec plusieurs écoles de magie coûtent 2 de Magie en moins`,
    ]
  },
]

export default aptitudes.map((a) => ({ ...a, level: 15 }));
