import { ButtonMenu } from 'components/ButtonMenu';

export function ManualMenu() {
  return (
    <>
      <ButtonMenu
        button={{ icon: 'book', text: `Manuel` }}
        menuItems={[
          { icon: 'dice', text: `Introduction`, url: '/manual' },
          { icon: 'images', text: `Afterworld`, url: '/manual/afterworld' },
          { icon: 'book-open', text: `Glossaire`, url: '/manual/glossary' },
        ]}
      />

      <ButtonMenu
        button={{ icon: 'user-secret', text: `Personnage` }}
        menuItems={[
          { icon: 'user-plus', text: `Le personnage`, url: '/manual/character/creation' },
          { icon: 'brain', text: `Compétences`, url: '/manual/character/skills' },
          { icon: 'user-astronaut', text: `Race`, url: '/manual/character/race' },
          { icon: 'heart-circle-bolt', text: `Passifs`, url: '/manual/character/passives' },
          { icon: 'heart-pulse', text: `Statistiques`, url: '/manual/character/statistics' },
          { icon: 'wand-magic-sparkles', text: `La magie`, url: '/manual/character/magic' },
        ]}
      />

      <ButtonMenu
        button={{ icon: 'hand-fist', text: `Combat` }}
        menuItems={[
          { icon: 'clock', text: `Déroulement`, url: '/manual/fight/round' },
          { icon: 'skull-crossbones', text: `Touche et dégâts`, url: '/manual/fight/damage' },
          { icon: 'hand-back-fist', text: `En mêlée`, url: '/manual/fight/melee' },
          { icon: 'person-rifle', text: `A distance`, url: '/manual/fight/ranged' },
          { icon: 'hourglass-half', text: `Spéciales`, url: '/manual/fight/special' },
          { icon: 'eye-low-vision', text: `Modificateurs`, url: '/manual/fight/modifiers' },
        ]}
      />

      <ButtonMenu
        button={{ icon: 'ellipsis', text: `Autre` }}
        menuItems={[
          { icon: 'wind', text: `Environnement`, url: '/manual/other/environment' },
          { icon: 'syringe', text: `Drogues`, url: '/manual/other/drugs' },
          { icon: 'notes-medical', text: `Etat de santé`, url: '/manual/other/states' },
          { icon: 'face-angry', text: `Folie`, url: '/manual/other/madness' },
          // { icon: 'graduation-cap', text: `Vocations`, url: '/manual/other/calling' },
        ]}
      />
    </>
  );
}
