
import { Text, Title, Quote } from 'components/semantic';
import { PageHeading } from 'components/elements';

export function ManualGlossaryPage() {
  return (
    <>
      <PageHeading>
        Glossaire
      </PageHeading>

      <Title>Test de compétence</Title>

      <Text>
        {`Dans ce jeu, lorsqu'un test de compétence est demandé, il faut lancer 1d100. Le test est réussi si le score obtenu est inférieur ou égal au score de la compétence, autrement le test est échoué.`}
      </Text>

      <Text>
        {`Par exemple, si pour s'orienter un test de Survie est demandé, si le score obtenu sur les dés est de 54 alors que la compétence en Survie est de 55, alors le test est réussi.`}
      </Text>

      <Title>Degré de réussite</Title>

      <Text>
        {`Lors d'un test de compétence, le degré de réussite s'obtient en comparant le score du dé et le score de la compétence. La différence des dizaines correspond au degré de réussite.`}
      </Text>

      <Text>
        {`Par exemple, lors d'un tir avec un Fusil Sniper avec une compétence en Armes légères à 85, le score obtenu sur le dé est 32. Le degré de réussite est de 5 : 8 - 3.`}
      </Text>

      <Quote>
        {`Le degré de réussite s'abrège avec DR`}
      </Quote>
    </>
  );
}
